<script>
import { toRaw } from "vue";

  import {
    required,
    helpers
  } from "@vuelidate/validators";

  import useVuelidate from "@vuelidate/core";
  import { mapActions} from "vuex";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  
  import prodottiDaConsegnare  from "@/components/widgets/worksites/prodottiDaConsegnare";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'gestioneConsegne',
    props: [
      'type',
      'projectID',
      'worksiteID',
      'taskID',
      'interventionID',
      'data',
    ],
    setup() {
      moment.locale('it')
      let tk = 'Bearer '+localStorage.getItem('tk')
      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Prodotti progetto",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          name:"",
          details:"",
          ownership:false,
          ownershipDesc:'',
          manufacturer:'',
          manufacturerDesc:'',
          kmStart:'',
          createdBy:this.$store.state.auth.currentUser,
        },
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        n_ordersResults:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        pageOrders: 1,
        perPageOrders: 10,
        responseOrdersList: [
        ],
        dbgruppo:'nd',
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
        dataEdit:{},
        minDate:moment().format('YYYY-MM-DDTHH:MM'),
        maxDate:moment().format('YYYY-MM-DD'),
        scheduleDelivery:{
          startStr:'',
          projectID:this.projectID,
          worksiteID:this.worksiteID, 
          endStr:"",
          name:"",
          details:"",
          dbgruppo:"",
        }
      };
    },
    components: {
      prodottiDaConsegnare
    },
    validations: {
      item: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        code: {
          required: helpers.withMessage("Codice identificativo prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà prodotto obbligatoria", required),
        },
      },
      dataEdit: {
        name: {
          required: helpers.withMessage("Nome prodotto obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca prodotto obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà del prodotto obbligatoria", required),
        },
        number: {
          required: helpers.withMessage("Indicazione numero di pezzi utilizzati obbligatorio", required),
        },


      },

    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      ...mapActions('ecommercesettings', ['addItemToOrder', 'getCartByOrd']),
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      resetDBGruppo(){
        this.qSearch=''
        this.resultsList = []  
      },
      async fullSearch(){
        if (this.dbgruppo!=='nd') {
          this.resultsList=[]
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}intervention-reports/search/products/fulltext`,
              { 
                params: { 
                  dbgruppo:this.dbgruppo,
                  q: this.qSearch,
                },
                headers: { 'authorization':this.tk} 
              }
          )
          .then((response) => {
              if (response.data)  {
              this.resultsList = response.data
              }
          })
        } else {
         Swal.fire({  
           title: "Magazzino mancante", 
           text: "Attenzione: devi indicare il magazzino aziendale di riferimento ", 
           icon:"warning",              
           showCancelButton:false,
           showConfirmButton:false,
           timer:5000
         })
       }


      },
      getList(){
       
        let urlRef= `items-by-projectid/${this.$route.params.id}`
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/${urlRef}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          this.getCarts()
          })
      },
      getCarts(){
       
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}carts/order/project/id/${this.$route.params.id}`, 
          { 
            params: { 
              perPage: this.perPageOrders,
              page: this.pageOrders,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseOrdersList = response.data.results.data;
          let rows = response.data.results.data.filter(function(el) {
            return el.doc_type == 'row'
          });
          let n = 0
          for (let i of rows) {
            if (i.detail_item.qta) {
              n+=i.detail_item.qta
            }
          }

          if (rows) {
            this.n_ordersResults = n
          } else {
            this.n_ordersResults = 0
          }
          
          this.paginatedOrders =  response.data.results
          this.checkItemsInOrder()
        
        })
      },
      checkItemsInOrder(){
        let self = this
        
        for (let item of this.responseOrdersList) {
          
          let items = self.responseList.filter(function(el){
            return el.CodArt == toRaw(item).gcode &&  toRaw(item).detail_item.qta==el.number
          }) 
          if(items){
            self.responseList.indexOf(toRaw(items[0]))
            toRaw(self.responseList[self.responseList.indexOf(toRaw(items[0]))]).inOrder = 1
          }
            
        }

      },
      prepProcOrder(){
        
        Swal.fire(
          {
            title: this.$t("t-processingorder-confirm-setting-title"),
            text: this.$t("t-processingorder-confirm-setting-text"),
            icon: this.$t("t-processingorder-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-processingorder-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-processingorder-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-processingorder-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-processingorder-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.processingOrder()
            }
        });
      },
      scheduleDel(){
       
       if (this.scheduleDelivery.startStr!='') {
         this.axiosInterceptor()
       
         if (this.scheduleDelivery.startStr!='')
         { 

          this.scheduleDelivery.task_type = 'task-worksite-delivery'
          this.scheduleDelivery.dateString = this.scheduleDelivery.startStr
          this.scheduleDelivery.dateTmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.year = moment().format('YYYY')
          this.scheduleDelivery.month = this.scheduleDelivery.dateString.split('-')[1]
          this.scheduleDelivery.day =  this.scheduleDelivery.dateString.split('-')[2]
          this.scheduleDelivery.start = moment(this.scheduleDelivery.startStr).valueOf()
          this.scheduleDelivery.start_tmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.end = moment(this.scheduleDelivery.startStr).valueOf()
          this.scheduleDelivery.end_tmp = moment(this.scheduleDelivery.startStr).unix()
          this.scheduleDelivery.createdBy = this.datastore.auth.currentUser
          this.scheduleDelivery.DBGruppo = this.data.DBGruppo
          
           axios.post(`${this.UrlServer}worksites/delivery/schedule`, this.scheduleDelivery, {headers: { authorization:this.tk}} )
             .then((response)=>{
              
               if (response.data) {
                   Swal.fire({  
                     title: this.$t("t-msg-delivery-init") , 
                     text: this.$t("t-msg-delivery-init-content"), 
                     icon:"success",              
                     showCancelButton:false,
                     showConfirmButton:false,
                     timer:5000
                     }).then(()=>{
                     
                      document.getElementById("closeScheduleModal").click(); 
                      this.processingWSOrder()
                     });
                }
             })
             
           }
       } else {
         Swal.fire({  
           title: "Dati mancanti", 
           text: "Attenzione: devi indicare la data di consegna in cantiere dei prodotti... ", 
           icon:"warning",              
           showCancelButton:false,
           showConfirmButton:false,
           timer:5000
         })
       }
     },
     processingWSOrder(){
      
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}carts/processing/worksite/order`, 
          {
            projectID: this.projectID
          }, 
          {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            //const childComponent = this.$refs.getProcCarts;
            if (response.data) {
            
              const childComponent = this.$refs.getProcCarts;
              if (response.data) {
                if (childComponent) {
                  childComponent.getProcCarts();
                } else {
                    console.error('Child component reference is undefined.');
                }
                Swal.fire({  
                  title: this.$t("t-msg-processing-order-init") , 
                  text: this.$t("t-msg-processing-order-init-content"), 
                  icon:"success",              
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000
                })
              }
            }
        })
      },
      processingOrder(){
      
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}carts/processing-order`, 
          {
            projectID: this.projectID
          }, 
          {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            //const childComponent = this.$refs.getProcCarts;
            if (response.data) {
              /*
              if (childComponent) {
                childComponent.getProcCarts();
              } else {
                  console.error('Child component reference is undefined.');
              }
                  */
              Swal.fire({  
                title: this.$t("t-msg-processing-order-init") , 
                text: this.$t("t-msg-processing-order-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
              if (response.data){
                if (response.data.cartID) {
                  this.sendOrderToERP(response.data.cartID)
                }
              }

            }
        })
      },
      sendOrderToERP(cartid){
        this.axiosInterceptor()
        if (this.data.customerDetails) {
          axios.post(`${this.UrlServer}orders/send-order-to-erp`, 
            {
              projectID: this.projectID,
              cartID: cartid,
              customerDetails: this.data.customerDetails,
            }, 
            {
              headers: { authorization:this.tk},
              onUploadProgress: (event) =>
              {
                const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                let timerInterval;
                Swal.fire({
                  title: "Caricamento...",
                  timer: percentUploaded,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                      timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('Caricamento...')
                  }
                });

              },
          } )
            .then((response)=>{
              const childComponent = this.$refs.getProcCarts;
              if (response.data) {
                if (childComponent) {
                  childComponent.getProcCarts();
                } else {
                    console.error('Child component reference is undefined.');
                }
                Swal.fire({  
                  title: this.$t("t-msg-processing-order-init") , 
                  text: this.$t("t-msg-processing-order-init-content"), 
                  icon:"success",              
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000
                })
              }
          })
        }

    },
      editdata(data) {
        this.dataEdit = data
      },
      
      initItem(){
        this.submitted = true;
        this.v$.$touch()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}equipments/register`, this.item, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
        } )
          .then((response)=>{
            if (response.data) {
              Swal.fire({  
                title: this.$t("t-msg-vehicle-init") , 
                text: this.$t("t-msg-vehicle-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                  document.getElementById("closemodalEquip").click();
              });
            }
        })
      },
      addElement(params){
        let date = moment().format('YYYY/MM/DD')
        params.projectID = this.projectID
        params.productID = params._id
        
        params.area = 'project'
        params.year = date.split('/')[0]
        params.day = date.split('/')[2]
        params.month = date.split('/')[1]
        params.number = 1
        /*
        if ( moment().day() <10 ){
            params.day =`0${moment().day()}`
        } else {
            params.day = moment().month()
        }
        */
        params.date_tmp = moment().unix()
        params.createdBy = this.datastore.auth.currentUser
        if (params._id){
          delete params._id
        }

        this.axiosInterceptor()
        axios.post(`${this.UrlServer}intervention-reports/add-product`, params, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
             // let timerInterval;
              console.log(percentUploaded)
              /*
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
                
              }).then((result) => {
                
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });
              */

            },
        } )
          .then((response)=>{
            if (response.data) {
              this.getList()
              this.resultsList = []
              this.search=''
              Swal.fire({  
                title: this.$t("t-msg-element") , 
                text: this.$t("t-msg-element-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              })
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}intervention-reports/worksite-item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      deleteInOrder(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}carts/item/${id}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      paginatedOrders(response) {
        let page = this.pageOrders;
        let perPage = this.perPageOrders;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return response.slice(from, to);
      },
      paginateOrdersAct(pg){
        this.pageOrders = pg
        this.getCarts()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
      },
      selectItem(params){
          let array = this.responseList.filter(function(el){
            return el._id == params._id
          })
          if (array.length==0){
            this.addElement(params)
          } else {
            Swal.fire({
              title:"Prodotto già presente",
              text:"Prodotto che hai selezionato è già presente!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
      },
      updatedata(){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}intervention-reports/worksite-item/${this.dataEdit._id}`, this.dataEdit, {
            headers: { authorization:this.tk},
            onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
            } )
            .then((response)=>{
              if (response.data) {
                document.getElementById("editclosemodalEquip").click();
                this.getList()
              }
          })
      },
      getCart(){
        if ( localStorage.getItem("orx") ) {
          this.getCartByOrd(localStorage.getItem("orx"))
        }
      },
      addTC(gcode, codMarca, qty){
        moment.locale('it')
        let momentCode = moment().unix()
        let orx = false
      
        if ( localStorage.getItem("orxpro") ) {
          orx = localStorage.getItem("orxpro")
        } else {
          localStorage.setItem("orxpro", orx)
        }
        let sr = 'mov-magazzino'

        let data = { 
          productCode: gcode,
          userId: this.data.customerDetails._id,
          projectID: this.data._id,
          cuuid:momentCode,
          orx:orx,
          salesRoute:sr,
          qta: qty,
        }
        this.addItemToOrder(data)
        let self = this
        setTimeout(function(){
          self.getList()
        },2000)
      },
      editQtaCart(gcode,qty){

        let id 
        let order = this.responseOrdersList.filter(function(el){
          return el.gcode == gcode
        })
        
    
        if (order && order.length>0) {
          
          id = order[0]._id
          let data = { 
            detail_item:{
              idProduct: order[0].detail_item.idProduct,
              Nome: order[0].detail_item.Nome,
              Descrizione: order[0].detail_item.Descrizione,
              qta: qty,
              prezzoListino: order[0].detail_item.prezzoListino,
              prezzo: order[0].detail_item.prezzo,
              codIva: order[0].detail_item.codIva
            }
          }
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}carts/${id}`,
            data,
            { 
              headers: { 'authorization':this.tk} 
            }
          )
          .then((response) => {
            
              if (response.data)  {
                this.getList()
              }
          })
        }

      }
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getList()
      this.dbgruppo=this.data.DBGruppo
    }
  };
</script>

<template>
<div class="back-grey mt-20">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <prodottiDaConsegnare :projectID="projectID" ref="getProcCarts" />
    </div>
  </div> 

  </div>
</template>